import React, { useState } from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const DashBoard = () => {
    const [key, setKey] = useState<string>('changed');

    //to navigate on new page
    const navigate = useNavigate();

    const handleNewReservation = () => {
        navigate('/client/reservation-manager/dashboard/accomodation-reservation');
    };

    return (
        <div className="container-fluid">
            {/* Basic Card-1 (Reservation)*/}
            <div className="card shadow mb-4" style={{ marginTop: '1rem' }}>
                <div className="card-header py-3">
                    <h4 className="m-0 font-weight-bold text-black">
                        <strong>Reservation</strong>
                    </h4>
                </div>
                <div className="details-container">
                    <div className="col-12">
                        <div className="card-body">
                            <Tabs
                                activeKey={key}
                                onSelect={(k: string | null) => k && setKey(k)}
                                id="changed"
                                className="mb-3"
                            >
                                {/* Tab 1 Content (Changed)*/}
                                <Tab eventKey="changed" title="Changed">
                                    {/*Before 1st scroll the rows should be by default 50 */}
                                    <div style={{ height: '350px', overflowY: 'auto' }}>
                                        <table className="employee-table">
                                            <thead>
                                                <tr>
                                                    <th>Status</th>
                                                    <th>
                                                        Res nr. <i className="fas fa-sort ml-2" />
                                                    </th>
                                                    <th>
                                                        Name <i className="fas fa-sort ml-2" />
                                                    </th>
                                                    <th>
                                                        Location <i className="fas fa-sort ml-2" />
                                                    </th>
                                                    <th>
                                                        Accomodation types <i className="fas fa-sort ml-2" />
                                                    </th>
                                                    <th>
                                                        Unit name <i className="fas fa-sort ml-2" />
                                                    </th>
                                                    <th>
                                                        Arrival-departure <i className="fas fa-sort ml-2" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span className="badge bg-primary">Definitive</span>
                                                    </td>
                                                    <td>000025001</td>
                                                    <td>John</td>
                                                    <td>Verina</td>
                                                    <td>Building A</td>
                                                    <td>Room A1</td>
                                                    <td>Fri 12 Dec 2025 - Mon 15 Dec 2025,</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="badge bg-success">Checked In</span>
                                                    </td>
                                                    <td>000026002</td>
                                                    <td>Thomas</td>
                                                    <td>Siena</td>
                                                    <td>Building B</td>
                                                    <td>Room B2</td>
                                                    <td>Sat 12 Dec 2025 - Sund 15 Dec 2025,</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="badge bg-warning text-black">Pre-Booking</span>
                                                    </td>
                                                    <td>000027003</td>
                                                    <td>Maria</td>
                                                    <td>Sorrento</td>
                                                    <td>Building C</td>
                                                    <td>Room C3</td>
                                                    <td>Mon 10 Feb 2025 - Sat 6 Mar 2025,</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span
                                                            className="badge-purple"
                                                            style={{
                                                                backgroundColor: ' #d3d3d3',
                                                                borderRadius: '6px',
                                                                padding: '3px 7px',
                                                                fontSize: '12px',
                                                                fontWeight: 'bold',
                                                                verticalAlign: 'middle',
                                                                textAlign: 'center',
                                                                color: '#000',
                                                            }}
                                                        >
                                                            Checked out
                                                        </span>
                                                    </td>
                                                    <td>000028004</td>
                                                    <td>Jerry</td>
                                                    <td>Capri</td>
                                                    <td>Building D</td>
                                                    <td>Room D4</td>
                                                    <td>Wed 2 Mar 2025 - Mon 15 Jun 2025,</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="badge bg-danger">Declined</span>
                                                    </td>
                                                    <td>000029005</td>
                                                    <td>Larry</td>
                                                    <td>frankfrut</td>
                                                    <td>Building E</td>
                                                    <td>Room E5</td>
                                                    <td>Thurs 31 Nov 2025 - Fri 15 Sept 2025,</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span
                                                            className="badge-purple"
                                                            style={{
                                                                backgroundColor: ' #FF7518',
                                                                borderRadius: '6px',
                                                                padding: '3px 7px',
                                                                fontSize: '12px',
                                                                fontWeight: 'bold',
                                                                verticalAlign: 'middle',
                                                                textAlign: 'center',
                                                                color: '#000',
                                                            }}
                                                        >
                                                            Provisional
                                                        </span>
                                                    </td>
                                                    <td>000031007</td>
                                                    <td>Pedro</td>
                                                    <td>Prawet</td>
                                                    <td>Building G</td>
                                                    <td>Room G7</td>
                                                    <td>Mon 1 sept 2025 - Fri 15 Dec 2025,</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="badge bg-secondary">Quotation</span>
                                                    </td>
                                                    <td>000032008</td>
                                                    <td>Andre</td>
                                                    <td>Dublin</td>
                                                    <td>Building H</td>
                                                    <td>Room H8</td>
                                                    <td>Fri 29 Nov 2025 - Mon 5 Sept 2025,</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span
                                                            className="badge-purple"
                                                            style={{
                                                                backgroundColor: ' #7B3CEC',
                                                                borderRadius: '6px',
                                                                padding: '3px 7px',
                                                                fontSize: '12px',
                                                                fontWeight: 'bold',
                                                                verticalAlign: 'middle',
                                                                textAlign: 'center',
                                                                color: '#fff',
                                                            }}
                                                        >
                                                            Optional
                                                        </span>
                                                    </td>
                                                    <td>000033009</td>
                                                    <td>Samir</td>
                                                    <td>Mumbai</td>
                                                    <td>Building I</td>
                                                    <td>Room I9</td>
                                                    <td>Wed 3 Jun 2025 - Mon 25 Aug 2025,</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr />
                                    <Modal.Footer>
                                        <Button variant="primary" size="sm" onClick={handleNewReservation}>
                                            New Accomodation Reservation
                                        </Button>
                                    </Modal.Footer>
                                </Tab>
                                {/* Tab 2 Content (Quotations)*/}
                                <Tab eventKey="quotaions" title="Quotations">
                                    <h3>Coming soon</h3>
                                </Tab>
                                {/* Tab 3 Content (Completed)*/}
                                <Tab eventKey="completed" title="Completed">
                                    <h3>Coming Soon</h3>
                                </Tab>
                                {/* Tab 4 Content (All)*/}
                                <Tab eventKey="all" title="All">
                                    <h3>Coming Soon</h3>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashBoard;
