// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .sidebar {
    width: 250px;
    padding: 20px;
  }
  
  .sidebar-title {
    color: #ff4081;
    font-weight: bold;
  }
  
  .sidebar-menu {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar-menu > li {
    padding: 10px 0;
    cursor: pointer;
  }
  
  .sidebar-menu > li:hover {
    text-decoration: underline;
  }
  
  .submenu {
    list-style-type: none;
    padding-left: 20px;
    margin-top: 5px;
  }
  
  .sidebar-footer {
    margin-top: 20px;
    font-size: 14px;
    color: gray;
  }
   
  .reservation-card {
    width: 400px;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }


/* Villa - Cards details*/
.blank-image {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.card-body p {
    font-size: 0.9rem;
    line-height: 1.4;
}

.d-flex span {
    font-size: 0.9rem;
    line-height: 1.4;
}
`, "",{"version":3,"sources":["webpack://./src/components/client/Reservation/Dashboard/AccomodationReservation.css"],"names":[],"mappings":"EAAE;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;;;AAGF,yBAAyB;AACzB;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":["  .sidebar {\r\n    width: 250px;\r\n    padding: 20px;\r\n  }\r\n  \r\n  .sidebar-title {\r\n    color: #ff4081;\r\n    font-weight: bold;\r\n  }\r\n  \r\n  .sidebar-menu {\r\n    list-style-type: none;\r\n    padding: 0;\r\n  }\r\n  \r\n  .sidebar-menu > li {\r\n    padding: 10px 0;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .sidebar-menu > li:hover {\r\n    text-decoration: underline;\r\n  }\r\n  \r\n  .submenu {\r\n    list-style-type: none;\r\n    padding-left: 20px;\r\n    margin-top: 5px;\r\n  }\r\n  \r\n  .sidebar-footer {\r\n    margin-top: 20px;\r\n    font-size: 14px;\r\n    color: gray;\r\n  }\r\n   \r\n  .reservation-card {\r\n    width: 400px;\r\n  }\r\n  \r\n  .card-title {\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n    margin-bottom: 20px;\r\n  }\r\n\r\n\r\n/* Villa - Cards details*/\r\n.blank-image {\r\n    height: 150px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    border-radius: 8px;\r\n}\r\n\r\n.card-body p {\r\n    font-size: 0.9rem;\r\n    line-height: 1.4;\r\n}\r\n\r\n.d-flex span {\r\n    font-size: 0.9rem;\r\n    line-height: 1.4;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
